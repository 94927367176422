import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { LogManager, autoinject, bindable } from 'aurelia-framework';
import { ValidatedUser } from '../../../types/Users';
import { ValidatedRole } from '../../../types/Roles';
import { DialogController } from 'aurelia-dialog';
import { AlertService } from '../../../services/util/Alert';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { ValidatedOrganization } from '../../../types/Organizations';
import { ProgramUser } from '../admin-edit-user-programs/admin-edit-user-programs';
import { Paginated } from '@feathersjs/feathers';
const log = LogManager.getLogger('dfp:admin');

@autoinject
export class AdminEditUser {
    controller: ValidationController
    user: ValidatedUser;
    organizations: ValidatedOrganization[];
    roles: ValidatedRole[];
    globalRoleId: number;
    @bindable programUser: ProgramUser[] = [];
    @bindable loading = false;

    constructor(
        private controllerFactory: ValidationControllerFactory,
        private dialogController: DialogController,
        private alerts: AlertService,
        private api: DatafiProAPI,
    ) {
        this.user = new ValidatedUser({}, this.api.files);
        this.controller = this.controllerFactory.createForCurrentScope();
    }

    async activate(user: ValidatedUser): Promise<void> {
        this.user = new ValidatedUser(user, this.api.files);
        this.globalRoleId = user.roles.filter((role) => role.role.scopes.includes('app'))[0]?.role_id;

        const orgs = await this.api.organizations.find({ query: { $limit: 50 } }) as Paginated<ValidatedOrganization>;
        this.organizations = orgs.data.map((org) => new ValidatedOrganization(org));

        const roles = await this.api.roles.find({ query: { scopes: { $contains: '["app"]' }, $limit: 50 } }) as Paginated<ValidatedRole>;
        this.roles = roles.data.map((role) => new ValidatedRole(role));
    }

    async saveUser(user: ValidatedUser, createNewUser?: boolean): Promise<void> {
        return this.controller.validate({
            object: user,
        }).then(async (result) => {
            if (!result.valid) {
                throw new Error('Form is not valid');
            }
            this.loading = true;
            const role = this.roles.find((role) => role.id === this.globalRoleId);
            if (!user.id) {
                return await this.createUser(user, role);
            } else {
                return await this.updateUser(user, role);
            }
        }).then((user: ValidatedUser) => {
            if (this.programUser.length) {
                this.updateUserRoles(user);
            }
            this.alerts.create({
                label: 'User saved',
                dismissable: true,
            });
            if (!createNewUser) {
                this.dialogController.ok(user);
            } else {
                this.loading = false;
                this.user = new ValidatedUser({}, this.api.files);
                this.globalRoleId = undefined;
                this.programUser = [];
            }
        }).catch((e) => {
            log.error(`[admin-edit-user]: Error saving ${user}: ${e.message}`);
            this.alerts.create({
                label: `Error saving user: ${e.message}`,
                level: 'error',
                dismissable: true,
            });
            this.loading = false;
        });
    }

    private async createUser(user: ValidatedUser, role: ValidatedRole) {
        const newUser = await this.api.users.create(user);
        if (role) {
            const newRole = await this.api.usersRoles.create({ user_id: newUser.id, role_id: this.globalRoleId });
            newUser.roles = [{ ...newRole, role }];
        }
        return newUser;
    }

    private async updateUser(user: ValidatedUser, role: ValidatedRole) {
        const existingUserRole = user.roles?.find((r) => r.role_id === this.globalRoleId);
        if (existingUserRole) {
            return this.api.users.patch(user?.id, user);
        }

        if (user.roles?.length) {
            await Promise.all(user?.roles.map((role) => role?.id && this.api.usersRoles.remove(role.id)));
        }

        if (role) {
            const newRole = await this.api.usersRoles.create({ user_id: user.id, role_id: this.globalRoleId });
            const patchedUser = await this.api.users.patch(user.id, user);
            patchedUser.roles = [{ ...newRole, role }];
            return patchedUser;
        }
    }

    private async updateUserRoles(user: ValidatedUser) {
        const updateProgram = async (programId: number) => {
            await this.api.programs.get(programId).then((result) => {
                this.api.programs.patch(programId, result);
            }).catch((e) => {
                this.alerts.create({
                    label: `Error saving user to program: ${e.message}`,
                    level: 'error',
                    dismissable: true,
                });
            });
        };

        return Promise.all(this.programUser.map(async (p) => {
            if (p.action === 'remove') {
                if (!p.userRoleId) return;
                await updateProgram(p.programId);
                return this.api.programUsersRoles.remove(p.userRoleId);
            } else if (p.action === 'create') {
                await updateProgram(p.programId);
                return this.api.programUsersRoles.create({ user_id: this.user.id ? this.user.id : user.id, program_id: p.programId, role_id: p.roleId });
            } else if (p.action === 'update') {
                await updateProgram(p.programId);
                return this.api.programUsersRoles.patch(p.userRoleId, { role_id: p.roleId });
            }
        }));
    }
}
