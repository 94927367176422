
import { autoinject, bindable, LogManager, PLATFORM } from 'aurelia-framework';
import { Paginated, Params } from '@feathersjs/feathers';

import { AlertService } from '../../services/util/Alert';

import { ValidatedUser } from '../../types/Users';
import { ValidatedRole } from '../../types/Roles';

import { AdminEditRole } from './admin-edit-role/admin-edit-role';
PLATFORM.moduleName('./admin-edit-role/admin-edit-role');

import { AdminEditUser } from './admin-edit-user/admin-edit-user';
PLATFORM.moduleName('./admin-edit-user/admin-edit-user');

import { AdminEditOrganization } from './admin-edit-organization/admin-edit-organization';
PLATFORM.moduleName('./admin-edit-organization/admin-edit-organization');

// Custom permissions dialog
import { AdminEditPermissions } from './admin-edit-permissions/admin-edit-permissions';
PLATFORM.moduleName('./admin-edit-permissions/admin-edit-permissions');

import { Field } from '@wsb_dev/datafi-shared/lib/types/Field';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { BrowserStorage } from '../../services/util/BrowserStorage';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { APIFile, GenerateCSVData, RequestData, SaveFileActionData } from '@wsb_dev/datafi-shared/lib/types';
import { AppConfig } from '../../services/util/AppConfig';
import { userHasPermission } from '@wsb_dev/datafi-shared/lib/util/users/userHasPermission';
import { Redirect, Router } from 'aurelia-router';
import { ValidatedOrganization } from '../../types/Organizations';

const log = LogManager.getLogger('dfp:admin');

@autoinject
export class AdminPage {

    RoleEditViewModel = AdminEditRole;
    UserEditViewModel = AdminEditUser;
    OrganizationEditViewModel = AdminEditOrganization;

    // Custom permissions dialog
    PermissionEditViewModel = AdminEditPermissions;

    Role: typeof ValidatedRole = ValidatedRole;
    User: typeof ValidatedUser = ValidatedUser;
    Organization: typeof ValidatedOrganization = ValidatedOrganization;

    roles: ValidatedRole[];
    organizations: ValidatedOrganization[];

    tokenUser: ValidatedUser;

    userSearch: string;
    params: Params = {
        $eager: 'roles.role',
    };

    tokenFields: Field[] = getFields([{
        name: 'token_name',
        path: 'token_name',
    }, {
        name: 'user.fullname',
        path: 'user.fullname',
        label: 'User',
    }, {
        name: 'user.username',
        path: 'user.username',
        label: 'Username',
    }, {
        path: 'enabled',
        name: 'enabled',
    }, {
        path: 'client_type',
        name: 'client_type',
    }, {
        path: 'clients',
        name: 'clients',
    }, {
        name: 'updatedAt',
        path: 'updatedAt',
        label: 'Last used/updated',
        type: 'date',
    }, {
        name: 'user.id',
        path: 'user.id',
        label: 'User ID',
    }, {
        name: 'token',
        path: 'token',
        label: 'Token',
    }, {
        path: 'id',
        name: 'id',
        label: 'Token ID',
    }])

    cacheKey = 'admin';

    actions = [
        {
            id: 'Query',
            service: 'api/v1/users',
            label: 'Export Users',
            icon: 'cloud_download',
            next: {
                id: 'GenerateCSV',
                fields: [
                    { name: 'id' },
                    { name: 'username' },
                    { name: 'email' },
                    { name: 'fullname' },
                    { name: 'createdAt' },
                    { name: 'updatedAt' },
                ],
                next: {
                    id: 'SaveFile',
                    name: 'users.csv',
                } as SaveFileActionData,
            } as GenerateCSVData,
        } as RequestData,
    ]

    jobsFields: Field[] = getFields([
        { label: 'ID', name: 'id', path: 'id', type: 'number', format: 'd' },
        { label: 'Created At', name: 'createdAt', path: 'createdAt', type: 'date' },
        { label: 'Updated At', name: 'updatedAt', path: 'updatedAt', type: 'date' },
        { label: 'Status', name: 'status', path: 'status' },
        { label: 'Name', name: 'name', path: 'name' },
        { label: 'Job Size (MB)', name: 'dataSize', path: 'dataSize', format: '.1r' },
        { label: 'Attempts', name: 'attempts', path: 'options.attempts', format: 'd' },
        { label: 'Delay', name: 'delay', path: 'options.delay', format: 'd' },
        { label: 'User ID', name: 'user_id', path: 'user_id', format: 'd' },
        { label: 'Program ID', name: 'program_id', path: 'program_id', format: 'd' },
        { label: 'Webhook ID', name: 'webhook_id', path: 'webhook_id', format: 'd' },
        { label: 'Result', name: 'result', path: 'result.id' },
        { label: 'Error', name: 'error', path: 'error' },
    ])
    jobsStatus = ['completed', 'failed', 'waiting', 'active', 'delayed'];

    organizationFields: Field[] = getFields([
        { name: 'id', path: 'id', type: 'number' },
        { name: 'name', path: 'name' },
    ]);

    @bindable
    folderPaths: string[] = [
        'templates',
    ]
    folderPathsChanged() {
        this.updateCache();
    }

    @bindable selectedFolderPath: string;
    selectedFolderPathChanged() {
        this.updateCache();

        if (!this.selectedFolderPath) {
            return;
        }
        this.api.files.find({ query: { $path: `${this.selectedFolderPath}/` } }).then((result: APIFile[]) => {
            this.selectedFiles = result.map((f) => ({
                id: f.id,
                name: f.id,
            }));
        });
    }

    selectedFiles: APIFile[];

    activePage?: string;
    pageLinks = [{
        label: 'Users',
        path: 'users',
        desc: 'Manage user data.',
        icon: 'person',
    },
    {
        label: 'Roles',
        path: 'roles',
        desc: 'Manage the permissions for global and app roles.',
        icon: 'verifieduser',
    },
    {
        label: 'Files',
        path: 'files',
        desc: 'Manage files and file structure.',
        icon: 'folder',
    },
    {
        label: 'Tokens',
        path: 'tokens',
        desc: 'Manage token data.',
        icon: 'key',
    },
    {
        label: 'Jobs',
        path: 'jobs',
        desc: 'View executed job data.',
        icon: 'work_history',
    },
    {
        label: 'Organizations',
        path: 'organizations',
        desc: 'Manage organization data.',
        icon: 'groups',
    }];

    constructor(
        private alerts: AlertService,
        private storage: BrowserStorage,
        private api: DatafiProAPI,
        private config: AppConfig,
        private router: Router,
    ) { }

    canActivate(params, routeConfig, navigationInstruction) {
        if (userHasPermission(this.api.auth.me, '*', '*')) {
            return true;
        }
        this.alerts.create({
            label: 'You do not have permission to access this page',
            level: 'warning',
            dismissable: true,
        });
        return new Redirect('/');
    }

    activate(params) {
        this.activePage = this.pageLinks.some((link) => link.path === params.page) ? params.page : null;
    }

    async bind() {
        this.tokenUser = new ValidatedUser({}, this.api.files);
        // fetch previously stored upload paths from user storage
        await this.storage.get(this.cacheKey)
            .catch((e) => {
                log.debug(e);
                return {
                    id: this.cacheKey,
                    data: {},
                };
            })
            .then((item) => {
                this.folderPaths = item.data?.folderPaths ? item.data.folderPaths : [];
                this.selectedFolderPath = item.data?.selectedFolderPath || 'templates';
            });

        // get roles
        await this.api.roles.find({
            query: { scopes: { $contains: '["app"]' }, $limit: 50 },
        }).then((result: Paginated<ValidatedRole>) => this.roles = result.data.map((r) => new ValidatedRole(r)));

        //get organizations
        await this.api.organizations.find({ query: { $limit: 50 } }).then((result: Paginated<ValidatedOrganization>) => this.organizations = result.data.map((org) => new ValidatedOrganization(org)));
    }

    searchUsers() {
        this.params = {
            $eager: 'roles.role',
        };
        if (this.userSearch.length > 1) {
            this.params = {
                ... this.params,
                $or: [
                    { username: { $ilike: '%' + this.userSearch + '%' } },
                    { email: { $ilike: '%' + this.userSearch + '%' } },
                    { fullname: { $ilike: '%' + this.userSearch + '%' } },
                ],
            };
        }
    }

    async updateUserRole(user: ValidatedUser, roleName: string) {
        if (user._pending) {
            return;
        }
        user._pending = true;
        const role = this.roles.find((role) => role.role_name === roleName);
        const existingUserRole = user.roles?.find((r) => r.role_id === role?.id);
        if (existingUserRole) {
            log.warn(`Role was already present and was not added: ${role.role_name}`);
            return;
        }

        // delete existing role
        if (user.roles?.length) {
            await Promise.all(user.roles?.map((role) => {
                if (!role?.id) { return; }
                return this.api.usersRoles.remove(role.id);
            }));
        }

        // create new role
        if (role) {
            await this.api.usersRoles.create({
                user_id: user.id,
                role_id: role.id,
            }).then((newRole) => {
                user.roles = [{ ...newRole, role }];
            });
        }

        // TODO fix caching on backend to remove this line
        await this.api.users.patch(user.id, {});
        user._pending = false;

        this.alerts.create({
            label: `${user.fullname || user.username} role was updated`,
            level: 'success',
            dismissable: true,
        });
    }

    async updateUserOrganization(user: ValidatedUser, id) {
        if (user._pending) {
            return;
        }
        user._pending = true;
        await this.api.users.patch(user.id, { organizationId: id });
        user._pending = false;

        this.alerts.create({
            label: `Organization updated for user: ${user.fullname || user.username}`,
            level: 'success',
            dismissable: true,
        });
    }

    updateCache() {
        return this.storage.create({
            id: this.cacheKey,
            data: {
                folderPaths: this.folderPaths,
                selectedFolderPath: this.selectedFolderPath,
            },
        });
    }

    addFolder(folder: string) {
        if (!folder) {
            return;
        }
        // update and select the given folder
        this.folderPaths.push(folder);
        this.folderPaths = Array.from(new Set(this.folderPaths));
        this.selectedFolderPath = folder;
    }

    getFileName = (f: File) => {
        return `${this.selectedFolderPath}/${f.name}`;
    }

    deleteFile(file: APIFile) {
        this.api.files.remove(file.id);
    }
}
